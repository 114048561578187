import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { breakpoints, colors } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { TextInput, Button, Pulse } from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { EventContext } from '../../components/context/EventContext'
import { useWithDelay } from '../../hooks/useWithDelay'
import { PhoneContext } from '../../components/context/PhoneContext'
import { CustomerContext } from '../../components/context/CustomerContext'
import { buildAskForQuoteBody } from '../../util'
import DemandeLayout from '../../components/DemandeLayout'
import { httpFetch } from '../../util/httpFetch'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const CompanyPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const { customer, setCustomer } = useContext(CustomerContext)

  const [error, setError] = useState()

  const { state: checkoutState, dispatch: dispatchCheckoutCtx } = useContext(
    CheckoutContext,
  )

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const {
    state: eventState,
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const schema = yup.object().shape({
    deliveryCompany:
      eventState.eventFraming === 'b2b'
        ? yup.string().required('Indiquez le nom de votre société')
        : yup.string().nullable(),
  })

  const noValidate = useNoValidate()

  const submitCompanyForm = async contactCompany => {
    if (customer) {
      httpFetch(
        `${process.env.GATSBY_VENUS_BACK_URL}/api/account/update-profile`,
        {
          method: 'POST',
          body: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            company: checkoutState.deliveryCompany,
          },
        },
      ).then(() => {
        setCustomer({
          ...customer,
          company: checkoutState.deliveryCompany,
        })
      })
    }

    const nextUrl = computeNextPageWithBaseUri('demande', 'societe', {
      isUserLoggedIn: !!customer,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, { state: { next: true } })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        contactCompany,
        categories,
      })

      // setSubmittingState({ isSubmitting: true, demandSource })
      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', {
          state: {
            quote: true,
          },
        })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: {
            demandSource: null,
          },
        })
      }
    }
  }

  const submit = () => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'societe', {
      isUserLoggedIn: !!customer,
    })

    if (nextUrl) {
      navigate(nextUrl, {
        state: { next: true },
      })
    } else {
      submitCompanyForm()
      return
    }
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal, deliveryCompanyProps } = useFormalWithProps(
    {
      deliveryCompany: checkoutState.deliveryCompany,
    },
    {
      schema,
      onSubmit: submitWithDelay,
    },
  )

  return (
    <StyledCompanyPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={
                <>
                  {`Merci ${checkoutState.contactFirstName},`}
                  <br />
                  {`Au nom de quelle société\u00A0?`}
                </>
              }
              subTitle={
                <p>
                  {`Notre offre est exclusivement réservée aux entreprises et associations\u00A0.`}
                </p>
              }
            />

            <p className="error">{error}</p>

            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err || formal.errors)
                }
              }}
            >
              <TextInput
                id="company"
                label="Entreprise"
                autoComplete="organization"
                secondary
                placeholder="ex: SNCF"
                required
                autoFocus
                {...deliveryCompanyProps}
                onChange={dc => {
                  formal.change('deliveryCompany', dc)
                  dispatchCheckoutCtx({
                    type: 'UPDATE',
                    payload: {
                      deliveryCompany: dc,
                    },
                  })
                }}
              />
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledCompanyPage>
  )
}

const StyledCompanyPage = styled.div`
  .page-content {
    max-width: 500px;
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .names {
    display: flex;
    > * {
      flex: 1 1 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .dates,
    .names {
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export default CompanyPage
export { StyledCompanyPage }
